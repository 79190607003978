import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [createPersistedState()],
  state: {
    token: "",
    user: {},
  },
  getters: {
    loginToken: (state) => {
      return state.token;
    },
    userInfos: (state) => {
      return state.user;
    },
  },
  mutations: {
    UPDATE_TOKEN(state, token) {
      state.token = token;
    },
    UPDATE_USER(state, user) {
      state.user = user;
    },
    LOGOUT(state) {
      state.token = "";
      state.user = {};
    },
  },
  actions: {
    CREATE_TOKEN({ commit }, toke) {
      commit("UPDATE_TOKEN", toke);
    },
    CREATE_USER({ commit }, use) {
      commit("UPDATE_USER", use);
    },
    IS_LOGOUT({ commit }, dec) {
      commit("LOGOUT", dec);
    },
  },
  modules: {},
});
