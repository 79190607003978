export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "emailErrorLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez un email valide"])},
  "passwordErrorLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre mot de passe (min: 6 caractères)"])},
  "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ? "])},
  "loginButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONNEXION"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
  "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnels"])},
  "takeInCharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prises en charge"])},
  "forum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forums"])},
  "searchDateType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date, type"])},
  "patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient"])},
  "takeInChargeBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pris en charge par"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "itWas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a "])},
  "agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGENTS"])},
  "adhesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adhésions SSR"])},
  "nameSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom, tél, mail..."])},
  "completeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom Complet"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
  "town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localité"])},
  "agentInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de l’agent"])},
  "profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profession"])},
  "hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôtpital"])},
  "offeredServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services offert"])},
  "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
  "invalidButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalider l’adhésion"])},
  "validButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider l’adhésion"])},
  "validateAccountSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte validé avec succès"])},
  "invalidateAccountSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte invalidé avec succès"])},
  "errorLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ou mot de passe incorrect."])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "noTakeInChargeAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune prise en charge ajoutée"])},
  "noForumAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun forum ajouté"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher"])},
  "noAgentAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun professionel SSR ajouté"])},
  "noAdhesionSSRAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune demande d'adhésion SSR ajoutée"])},
  "passwordChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement de mot de passe"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer mot de passe"])},
  "savingButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "newPasswordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un nouveau mot de passe (min: 6 caractères)"])},
  "notSamePasswordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots de passes non conformes"])},
  "successPasswordChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe modifié avec succès"])},
  "changeMyPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer mon mot de passe"])},
  "MyInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes informations"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "technicalReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de réference technique"])},
  "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "successUpdateUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos informations ont été modifiées avec succès"])},
  "geographicalCoordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées géographiques"])}
}