import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { VueSvgIconPlugin } from "@yzfe/vue3-svgicon";
import "@yzfe/svgicon/lib/svgicon.css";
import install from "./install";
import i18n from "./i18n";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyABddMikdBQ3XwYJVUMbHsuf1jTFuNInkI",
  authDomain: "passhealth-14735.firebaseapp.com",
  projectId: "passhealth-14735",
  storageBucket: "passhealth-14735.appspot.com",
  messagingSenderId: "743872911346",
  appId: "1:743872911346:web:fab6d731a005b3790dfd6b",
  measurementId: "G-M7BFKKTQVN",
};

export const db = initializeApp(firebaseConfig);
export const firestore = getFirestore();
export const analytics = getAnalytics(db);

createApp(App)
  .use(i18n)
  .use(install)
  .use(VueSvgIconPlugin, { tagName: "icon" })
  .use(store)
  .use(router)
  .mount("#app");
