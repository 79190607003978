import { createRouter, createWebHistory } from "vue-router";
import pathLoader from "../plugins/loader";
import store from "@/store";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: pathLoader("login", "index"),
  },
  {
    path: "/",
    name: "Home",
    component: pathLoader("home", "index"),
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: pathLoader("dashboard", "index"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/prise-en-charge",
        name: "TakeInCharge",
        component: pathLoader("take-in-charge", "index"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/forum",
        name: "Forum",
        component: pathLoader("forum", "index"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/professional",
        name: "Professional",
        component: pathLoader("professional", "index"),
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    // console.log(store.getters.loginToken)
    if (store.getters.loginToken.length > 1) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
